<template>
  <!--  <nav-bar/>-->
  <div class="body">
    <img src="../assets/lt.png" style="height: 100%;width: 100%;"/>
    <div class="detail">
      <div id="container">

      </div>
      <div class="input">
        <input @focus="focus" v-model="input" placeholder="输入您的联通手机号">
      </div>
      <div class="btn">
        <div class="text" @click="btnClick">点击领取福利</div>
        <div class="position">领完为止</div>
        <!--        <button>点击领取福利</button>-->
      </div>
      <div class="checkbox">
        <input type="checkbox" v-model="option" name="option" value="value">
        <div>我已经阅读并同意
          <div class="span" @click="privacy">《用户隐私政策》</div>
          <div class="span" @click="service">《服务协议》</div>
        </div>
      </div>
      <div style="font-size: 0.7rem;color: #fff">资费19.9元/月</div>
    </div>
    <div class="vant">
      <van-dialog v-model:show="privacyVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Privacy/>
        </div>
      </van-dialog>
      <van-dialog v-model:show="serviceVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Service/>
        </div>
      </van-dialog>
      <!--      <van-dialog v-model:show="iframeVisible" class="myIframePaneldialog" show-cancel-button @confirm="confirm">-->
      <!--        <div class="myIframePanel" id="scrollableDiv">-->
      <!--          <div class="myIframePanel1">-->
      <!--            <iframe-->
      <!--                id="myIframe" ref="myIframe" width="100%" height="100%"-->
      <!--                src="https://unipayphone.wostore.cn/sitepay/member_pay.html?torder=23e2c71b274645e89158eebc48ef589b&sign=41b4fd3fe3424c0ffaa31b7b571aa9f7"></iframe>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </van-dialog>-->
    </div>
  </div>
</template>

<script>

import {Toast} from 'vant';
import {push, subscribe} from "@/api";
import Privacy from "@/views/privacy.vue";
import Service from "@/views/service.vue";
import {px2Rem, px2Rem1} from "@/utils/rem";
import {decode} from "js-base64";

export default {
  name: "index",
  components: {Service, Privacy},
  data() {
    return {
      input: "",
      option: false,
      privacyVisible: false,
      serviceVisible: false,
      iframeVisible: false,
      query: {},
    }
  },
  created() {


    push({
      type: 0,
    })
    this.query = this.$route.query
    if (this.$route.query?.phone) {
      this.query.phone = decode(decodeURIComponent(this.$route.query.phone))
      console.log(this.query.phone)
      let regex = /^1[3456789]\d{9}$/;

      if (regex.test(this.query.phone)) {
        this.input = this.query.phone
      } else {
        this.query.phone = ""
      }
    }
  },
  mounted() {
    this.danMuFuc()
  },
  methods: {
    // btnClick1() {
    //   this.iframeVisible = true
    //
    //   this.setupIframe()
    //   const time = setInterval(() => {
    //     const iframe = this.$refs.myIframe;
    //     if (iframe) {
    //       this.setupIframe()
    //       clearInterval(time)
    //     }
    //   }, 100)
    //
    // },
    // setupIframe() {
    //   let num = px2Rem1(16)
    //   const iframe = this.$refs.myIframe;
    //   if (!iframe) {
    //     return;
    //   }
    //   iframe.onload = () => {
    //     let scrollableDiv = document.getElementById('scrollableDiv');
    //     scrollableDiv.scrollTop = px2Rem1()
    //     scrollableDiv.style.overflow = "hidden"
    //     console.log(num * 15.62)
    //     scrollableDiv.addEventListener("scroll", e => {
    //       console.log("111", scrollableDiv.scrollTop)
    //     })
    //
    //   };
    // },
    btnClick1() {
      this.option = true
      push({
        type: 1,
      })
      if (this.option) {
        let regex = /^1[3456789]\d{9}$/;

        // 测试输入的手机号是否符合格式
        if (regex.test(this.input)) {
          let toast = Toast.loading({
            duration: 0, // 持续展示 toast
            message: '加载中...',
            forbidClick: true,
          });
          subscribe({
            userCode: this.input,
            channelId: this.query.channelId ? this.query.channelId : null,
            phone: this.query.phone,
            parameter: this.query.a_oId ? JSON.stringify({a_oId: this.query.a_oId}) : null,
            url: "https://ltqyokc.hangzhouluofeng.com/#/"
          }).then(res => {
            if (res.success) {
              toast.clear()
              window.location.href = res.data.url
            } else {
              Toast(res.message)
            }
          })
        } else {
          Toast('手机号格式错误')
        }
      } else {
        Toast('请阅读并同意《用户隐私政策》')
      }
    },
    btnClick() {
      this.option = true
      push({
        type: 1,
      })
      if (this.option) {
        let regex = /^1[3456789]\d{9}$/;

        // 测试输入的手机号是否符合格式
        if (regex.test(this.input)) {
          let toast = Toast.loading({
            duration: 0, // 持续展示 toast
            message: '加载中...',
            forbidClick: true,
          });
          subscribe({
            userCode: this.input,
            channelId: this.query.channelId ? this.query.channelId : null,
            phone: this.query.phone,
            parameter: this.query.a_oId ? JSON.stringify({a_oId: this.query.a_oId}) : null,
            url: "https://testcdn2.hangzhouluofeng.com/#/"
          }).then(res => {
            if (res.success) {
              toast.clear()
              window.location.href = res.data.url
            } else {
              Toast("该号码不支持订购本产品，正在为您匹配新产品")
              setTimeout(() => {
                if (this.query.channelId) {
                  window.location.href = "http://ltspc.hangzhouluofeng.com/#/?channelId=" + this.query.channelId + "&phone=" + this.input
                } else {
                  window.location.href = "http://ltspc.hangzhouluofeng.com/#/?phone=" + this.input
                }
              }, 1000)
            }
          })
        } else {
          Toast('手机号格式错误')
        }
      } else {
        Toast('请阅读并同意《用户隐私政策》')
      }
    },
    privacy() {
      this.privacyVisible = true
    },
    service() {
      this.serviceVisible = true
    },
    confirm() {
      this.option = true
    },
    focus() {
      push({
        type: 2,
      })
    },
    danMuFuc() {
      function createDanMu(text, type) {
        // 创建一个span元素
        let danMu = document.createElement("span");
        // 设置其文本内容为参数值
        danMu.innerText = text;
        // 设置其类名为danmu
        danMu.className = "danMu";

        // 判断随机数是否能被2整除
        if (type) {
          danMu.style.top = 0
        }
        // danMu.style.backgroundColor = "rgb(" + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + ")";
        // 将span元素插入到容器元素中
        container.appendChild(danMu);
        // 使用CSS动画来让span元素从右向左移动
        danMu.style.animation = "move 6s linear infinite";
        setTimeout(() => {
          danMu.className = "danMu danMu1"
        }, 5000)
        setTimeout(() => {
          danMu.remove()
        }, 6000)
      }

      // 获取容器元素
      let container = document.getElementById("container");
      // 定义一个数组用来存放一些弹幕内容
      let texts = [
        "130*** 2分钟前领取成功",
        "132*** 3分钟前领取成功",
        "176*** 2分钟前领取成功",
        "156*** 2分钟前领取成功",
        "166*** 3分钟前领取成功",
        "166*** 5分钟前领取成功",
        "145*** 4分钟前领取成功",
        "155*** 5分钟前领取成功",
        "185*** 3分钟前领取成功",
        "171*** 4分钟前领取成功",
        "175*** 5分钟前领取成功",
        "186*** 4分钟前领取成功",
      ];
      let type = true
      let length = 0
      // 定义一个定时器，每隔一秒创建并插入一条弹幕
      setInterval(function () {
        // 随机选择一个弹幕内容
        let text = texts[length];
        if (length === 11) {
          length = 0
        } else {
          length++
        }
        createDanMu(text, type);
        type = !type
      }, 1000);
    }
  }
};
</script>

<style lang="less">
body {
  .van-toast {
    width: 12rem;
    text-align: center;
    //background-color: #01d0c0;
  }

  .van-popup--center {
    top: 40%;
  }

  .van-toast--text {
    font-size: .8rem;
  }

  .panel {
    height: 500px;
    overflow-y: auto;

  }

  .myIframePaneldialog {
    .van-dialog__footer {
      display: none;
    }
  }

  .myIframePanel {
    height: 16rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;


    .myIframePanel1 {
      height: 800px;
      width: 100%;
    }
  }

  .myIframePanel::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  #uniauthframe {
    body {
      overflow: hidden;
    }
  }

  .van-dialog {
    width: 20rem;
  }

  .van-dialog__cancel {
    height: 2rem;
  }

  .van-dialog__confirm {
    height: 2rem;
  }

  .vant {
    .van-popup--center {
      top: 50%;
    }
  }
}

@keyFrames mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

.body {
  margin: 0 auto;
  padding: 0;
  position: relative;
  max-width: 750px;
  //padding: 0 1rem
  .detail {
    width: 100%;
    position: absolute;
    top: 25.41rem;
    text-align: center;
  }

  .input {
    width: 12.93rem;
    height: 2.42rem;
    margin: 0 auto;

    input {
      border: none;
      border-radius: 1.87rem;
      padding: .75rem 2rem;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      text-align: center;
    }

    input::placeholder {
      text-align: center;
      font-size: .93rem;
    }
  }

  .btn {

    width: 13.05rem;
    height: 2.45rem;
    margin: 2.56rem auto 0;
    line-height: 2.45rem;
    text-align: center;
    position: relative;
    z-index: 1;
    animation: mymove .7s infinite;
    -webkit-animation: mymove .7s infinite;

    .text {

      font-size: 1.44rem;
      //font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #FFFFFF;
      border-radius: 1.22rem;
      border: 0.09px solid #F8E5B8;
      background: linear-gradient(0deg, #FD8A02, #FFF5A5);

    }

    .position {
      position: absolute;
      right: 0;
      top: -1rem;
      width: 4.5rem;
      height: 1.63rem;
      z-index: -1;
      line-height: 1.1rem;
      box-sizing: border-box;
      background: linear-gradient(-90deg, #25A0FF, #01D0C0);
      font-size: 0.81rem;
      text-align: center;
      font-weight: normal;
      color: #FFFFFF;
      clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 200%);
    }

  }

  .checkbox {
    margin-top: 1.38rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: inline-block;
      width: 0.69rem;
      height: 0.69rem;
      //width: 0.69rem;
      //height: 0.69rem;

      //border: 1px solid #E54243;
    }

    div {
      //width: 5.91rem;
      //height: 1rem;
      line-height: 1rem;
      font-size: 0.7rem;
      color: #3F3F3F;
      margin-left: 2px;
      display: inline-block;

      .span {
        color: #fff;
      }
    }
  }

  #container {
    width: 100%;
    height: 4rem;
    //background-color: black;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.63rem;
  }

  .danMu {
    position: absolute;
    top: 2.38rem;
    right: 0;
    white-space: nowrap;
    color: #fff;
    padding: 0 15px;
    background: #75C971;
    border-radius: 0.72rem;
    height: 1.44rem;
    line-height: 1.44rem;
    font-size: 0.69rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);;
  }

  .danMu111 {
    transform: translateX(100%);
    transition: transform 5s ease-in-out;
  }

  .danMu111 {
    transform: translateX(0);
  }

  @keyframes move {
    from {
      right: -10rem;
    }
    to {
      right: 100%;
    }
  }


}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  opacity: 0;
}
</style>
